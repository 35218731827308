import $ from 'jquery';
window.$ = $;
window.jQuery = $;

import './libs/slick.js';

const header = $('.header-container:not(.no-fade)');
const scrollHeightToShowBackground = 500;

let rotatorInterval = null;

// console.log('www');
$('.circle-item').on('mouseover', function () {
    let imgUrl = $(this).data('img-url')
    // console.log(imgUrl);
    $('.circle-back-image').css({
        'background': `url(`+imgUrl+`)`,
        'background-position': 'center center',
        'background-size': 'cover'
    })
});

$(document).on('click', '.slick-next', function() {
    $(this).parents('.slick-slider').eq(0).slick('slickNext');
});

$(document).on('click', '.slick-prev', function() {
    $(this).parents('.slick-slider').eq(0).slick('slickPrev');
});

var howItWorksSlider = null;

const initHowItWorksSlick = (index) => {
    howItWorksSlider = $('#how-it-works-wrap').slick({
        autoplay: false,   // Auto-play the slider
        dots: false,       // Show pagination dots
        arrows: false,     // Show navigation arrows
        infinite: true,   // Loop the slider
        speed: 1000,       // Transition speed in milliseconds
        slidesToShow: 1,  // Number of slides to show at a time
        slidesToScroll: 1, // Number of slides to scroll at a time
        initialSlide: index ?? 0,
        swipe: false
    });
}

$(document).ready(function () {


    $(document).ready(function() {
        $(".page-loader-wrapper").hide();
    });

    $('.pr-slider').slick({
        autoplay: false,   // Auto-play the slider
        dots: true,       // Show pagination dots
        arrows: false,     // Show navigation arrows
        infinite: true,   // Loop the slider
        speed: 100,       // Transition speed in milliseconds
        slidesToShow: 1,  // Number of slides to show at a time
        slidesToScroll: 1 // Number of slides to scroll at a time
    });

    initHowItWorksSlick();

    let slickSettings = {
        arrows: true,
        dots: false,
        infinite: true,
        speed: 300,
        centerPadding: '60px',
        slidesToShow: 4,
        slidesToScroll: 1,
        prevArrow: '<a class="slick-prev"><i class="fas fa-arrow-left" alt="Arrow Icon"></i></a>',
        nextArrow: '<a class="slick-next"><i class="fas fa-arrow-right" alt="Arrow Icon"></i></a>',
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    arrows: false,
                    centerMode: true,
                    centerPadding: '25px',
                    infinite: false,
                    dots: false,
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    $('.ltn__category-slider-active').slick(slickSettings);

    $('.ltn__testimonial-slider-6-active').slick({
        arrows: true,
        dots: false,
        centerMode: false,
        centerPadding: '80px',
        infinite: true,
        speed: 300,
        slidesToShow: 2,
        slidesToScroll: 1,
        prevArrow: '<a class="slick-prev"><i class="fas fa-arrow-left" alt="Arrow Icon"></i></a>',
        nextArrow: '<a class="slick-next"><i class="fas fa-arrow-right" alt="Arrow Icon"></i></a>',
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 992,
                settings: {
                    arrows: false,
                    dots: true,
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                    dots: true,
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 580,
                settings: {
                    arrows: false,
                    dots: true,
                    centerMode: false,
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });

    $('.ltn__blog-slider-one-active').slick({
        arrows: true,
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        prevArrow: '<a class="slick-prev"><i class="fas fa-arrow-left" alt="Arrow Icon"></i></a>',
        nextArrow: '<a class="slick-next"><i class="fas fa-arrow-right" alt="Arrow Icon"></i></a>',
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows: false,
                    dots: true
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows: false,
                    dots: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows: false,
                    dots: true
                }
            },
            {
                breakpoint: 575,
                settings: {
                    arrows: false,
                    dots: true,
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });

    $('.ltn__search-by-place-slider-1-active').slick({
        arrows: true,
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        prevArrow: '<a class="slick-prev"><i class="fas fa-arrow-left" alt="Arrow Icon"></i></a>',
        nextArrow: '<a class="slick-next"><i class="fas fa-arrow-right" alt="Arrow Icon"></i></a>',
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    arrows: false,
                    dots: true
                }
            },
            {
                breakpoint: 992,
                settings: {
                    arrows: false,
                    dots: true,
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                    dots: true,
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 580,
                settings: {
                    arrows: false,
                    dots: true,
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });
});

let degrees = 0;
let stepIndex = 1;

$(document).ready(function() {

    // Set initial rotation
    rotateEclipse(0);

    // Set styles for the initial step
    updateStepStyles(stepIndex);

    startRotator();
});

function startRotator() {

    rotatorInterval = setInterval(function() {

        stepIndex++;

        degrees = 72 * (stepIndex - 1);

        let normalizedAngle = degrees % 360
        let index = normalizedAngle / 72 + 1

        // console.log(normalizedAngle, index);


        rotateEclipse(degrees);
        updateStepStyles(index);

    }, 2500);
}

function rotateEclipse(degrees) {
    $('.circle').css({
        'transform': 'rotate(' + degrees + 'deg)',
        'transition': 'transform 1s ease'  // Add a smooth transition effect
    });
}

function updateStepStyles(index) {

    // Reset styles for all steps
    $('.rotator .step').css({
        // 'font-weight': 'normal',
        // 'font-size': 'px'
    });

    // Set styles for the current step
    $('.rotator .step-' + index).css({
        'font-weight': 'bold',
        'font-size': $('.rotator').data('font-size')
    });


    // Toggle active/inactive classes for step text
    $('.how-it-works-wrap .step-text').removeClass('active').addClass('inactive');
    $(`.how-it-works-wrap .step-${index}-text`).addClass('active').removeClass('inactive');

    let howItWorksVersion = $('#how-it-works').attr('data-version').trim();
    if(howItWorksVersion === 'mobile') {
       slideTo(index-1);
    }
}

function slideTo(index) {
    howItWorksSlider.slick('slickGoTo', index);
}


document.addEventListener('visibilitychange', function () {
    if (document.hidden) {
        // Tab is hidden or inactive, stop the interval
        stopRotator();
    } else {
        // Tab is visible and active, start or resume the interval
        startRotator();
    }
});

function stopRotator() {
    clearInterval(rotatorInterval);
}

$(window).scroll(function () {
    // console.log($(this).offsetY);
    $(window).scroll(function () {
        const scrollTop = $(this).scrollTop();
        const opacity = Math.min(scrollTop / scrollHeightToShowBackground, 2); // Calculate opacity based on scroll position

        let color = null;
        let boxShadow = null;

        if(opacity < 0.2) {
            color = `rgba(242, 246, 247, 1)`;
            boxShadow = 'none';

        }
        else {
            color = `rgba(255, 255, 255, ${opacity})`;
            boxShadow = '0 0 40px 1px #dadada';
        }

        header.css({
            'box-shadow': boxShadow,
            'background-color': color
        });
        // header.css('background-color', 'rgba(255, 255, 255, 1)');
    });
});

